export function basicHtmlSwal(title, body) {
    return `<p class="title">${title}</p>
        <br />
        <p class="info">${body}</p>`
}

export function htmlSwal(title, body) {
    const basicHtml = basicHtmlSwal(title, body)

    return `<div class="centered-container">
        ${basicHtml}
    </div>`
}

export function getBasicConfigSwal(title, body) {
    const configSwal = {}
    configSwal.allowEscapeKey = false
    configSwal.allowOutsideClick = false
    configSwal.showCancelButton = false

    configSwal.html = htmlSwal(title, body)
    if (!configSwal.customClass) {
        configSwal.customClass = {}
    }
    configSwal.customClass.popup = "swal-custom-styled-info no-cancel-btn"
    configConfirmButtonClose(configSwal)

    return configSwal
}

export function getWarningConfigSwal(title, body, addTextError = false) {
    const error = body === "" ? "não informado" : body
    const basicHtml = basicHtmlSwal(title, addTextError ? `Erros: ${error}` : error)

    const configSwal = getBasicConfigSwal(title, body)
    configSwal.customClass.popup = "swal-custom-styled-warning no-cancel-btn"
    configSwal.html = `<div class="centered-container">
        <i class="fa fa-exclamation-triangle icon-styled icon-styled-warning" aria-hidden="true"></i>
        <br />
        ${basicHtml}
    </div>`

    return configSwal
}

export function getSuccessConfigSwal(title, body) {
    const basicHtml = basicHtmlSwal(title, body)
    const configSwal = getBasicConfigSwal(title, body)
    configSwal.customClass.popup = "swal-custom-styled-success no-cancel-btn"
    configSwal.html = `<div class="centered-container">
        <i class="fa fa-check icon-styled icon-styled-success" aria-hidden="true"></i>
        <br />
        ${basicHtml}
    </div>`

    return configSwal
}

export function getInfoConfigSwal(title, body, closeIn) {
    const basicHtml = basicHtmlSwal(title, body)
    const configSwal = getBasicConfigSwal(title, body)
    configSwal.customClass.popup = "swal-custom-styled-info no-cancel-btn"
    configSwal.html = `<div class="centered-container">
        <i class="fa fa-info-circle icon-styled icon-styled-info" aria-hidden="true"></i>
        <br />
        ${basicHtml}
    </div>`

    if (closeIn && closeIn > 0) {
        configSwal.timer = closeIn
    }

    return configSwal
}

export function configConfirmButtonClose(configSwal) {
    configSwal.confirmButtonText = "Fechar"
    configSwal.customClass.confirmButton = "btn-swal-info"
}

export function configCancelButtonClose(configSwal) {
    if (!configSwal.customClass) {
        configSwal.customClass = {}
    }
    if (configSwal.customClass.popup) {
        configSwal.customClass.popup = configSwal.customClass.popup.replace("no-cancel-btn", "")
    }
    configSwal.showCancelButton = true

    configSwal.cancelButtonText = "Fechar"
    configSwal.customClass.cancelButton = "btn-swal-white"
}

export function configConfirmButtonInfo(configSwal, buttonText) {
    configSwal.customClass.confirmButton = "btn-swal-info swal2-styled"
    configSwal.confirmButtonText = buttonText
}

export function configConfirmButtonWarning(configSwal, buttonText) {
    configSwal.customClass.confirmButton = "btn-swal-warning"
    configSwal.confirmButtonText = buttonText
}

export function configConfirmButtonSuccess(configSwal, buttonText) {
    configSwal.customClass.confirmButton = "btn-swal-success"
    configSwal.confirmButtonText = buttonText
}
