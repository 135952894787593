import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.item.characteristics.grid",
        path: "settings/item/characteristics/grid",
        components: {
            default: () => import("@/pages/itemCharacteristicsSettings/ItemCharacteristicsGridSettingsIndex"),
        },
        meta: {
            title: "Características",
            accessPermission: "G1",
            description: "Adicione características de produtos, serviços e MRR e suas opções, formando grades de produtos para utilizar em propostas.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Características",
                    show: ".*",
                },
            ],
        },
    },
]
