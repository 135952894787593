import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "dealRequiredFieldRules.index",
        path: "settings/deal-required-field-rules",
        component: () => import("@/pages/dealRequiredFieldRules/DealRequiredFieldRulesIndex"),
        meta: {
            title: "Regras de preenchimento de campos",
            description: "Gerencie a obrigatoriedade do preenchimento de campos nativos e/ou customizados para realizar ações nas oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Regras de preenchimento de campos",
                    show: ".*",
                },
            ],
        },
    },
]
