import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "lossReasons.index",
        path: "settings/loss-reasons",
        component: () => import("@/pages/lossReasonsSettings/LossReasonsIndex"),
        meta: {
            title: "Motivos de perda",
            description: "Gerencie os motivos de perda de suas oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Motivos de perda",
                    show: ".*",
                },
            ],
        },
    },
]
