
export default [
    {
        name: "userEmail.integration",
        path: "email-integration",
        meta: { isPublic: true },
        props: true,
        component: () => import("@/pages/userSettings/components/userEmailsElements/UserEmailIntegration"),
    },
]
