<template>
    <div class="pipe-login">
        <h1>Você será redirecionado para o Piperun Max.</h1>
        <spinner-loader visible />
    </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader"

export default {
    components: {
        SpinnerLoader,
    },

}
</script>

<style lang="less" scoped>
@import "src/assets/styles/globals.less";

.pipe-login {
    background-color: white;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;

    h1 {
        font-weight: 400;
        margin-top: 80px;
        color: black;
    }
}
</style>
