<template>
    <div class="login-sidebar">
        <div class="login-sidebar__row">
            <img
                :src="$static.pipeLoginLogo"
                alt="Logo principal"
            >
            <slot />
        </div>
    </div>
</template>

<style lang="less">
@import "src/assets/styles/globals.less";

.login-sidebar {
    padding: 32px;
    background: #fff;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.35);
    z-index: 30;
    display: flex;
    flex: 1;
    flex-basis: 25%;
    flex-direction: column;
    justify-content: center;
    min-width: 350px;
    height: 100%;
    min-height: 100vh;

    @media @mobile {
        width: 100%;
        min-width: 0;
        padding: 16px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;

        & + & {
            margin-bottom: 16px;
        }

        img {
            width: 100%;
            max-width: 250px;
            height: auto;
            margin-bottom: 16px;
        }

        .login-form,
        .recover-password-form {
            flex: 1;
        }
    }
}
</style>
