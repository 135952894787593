import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.proposals",
        path: "administration/proposals",
        component: () => import("@/pages/administration/ProposalIndex"),
        meta: {
            pageTitle: "Layouts de proposta | Administração",
            title: "Layouts de proposta",
            description: "Controle layouts de propostas disponíveis para as contas.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Layout de proposta",
                    show: ".*",
                },
            ],
        },
    },
]
