import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "automaticMovements.index",
        path: "settings/automatic-movements",
        component: () => import("@/pages/automaticMovementsSettings/AutomaticMovementsIndex"),
        meta: {
            title: "Cadência de funil",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Cadência de funil",
                    show: ".*",
                },
            ],
        },
    },
]
