import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "importer.user",
        path: "importer/users",
        component: () => import("@/pages/importer/UserImporter"),
        meta: {
            title: "Importação de usuários",
            description: "Importe usuários para sua conta no Piperun a partir de um CSV, XLS ou XLSX.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Importação",
                    href: "/importacao",
                    show: ".*",
                    external: true,
                },
                {
                    label: "Usuários",
                    show: ".*",
                },
            ],
            buttons: null,
        },
    },
]
