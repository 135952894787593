import { http } from "@/plugins/http"

export const listProposalParcels = ({ commit }, query) => {
    return new Promise((resolve, reject) => {
        http.get("proposalParcels", { params: query }).then(({ data }) => {
            const obj = {
                list: data.data,
                pagination: data.meta,
            }
            commit("SET_DATA", obj)
            resolve(obj)
        }, error => {
            reject(error)
        })
    })
}

export const listProposalParcelsStats = ({ commit }, query) => {
    return new Promise((resolve, reject) => {
        http.get("proposalParcels/stats", { params: query }).then(({ data }) => {
            const obj = {
                stats: data.data,
            }
            commit("SET_STATS", obj)
            resolve(obj)
        }, error => {
            reject(error)
        })
    })
}
