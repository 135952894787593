import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const FACEBOOK_META = {
    pageTitle: "Integrações | Facebook Lead Ads",
    title: "Configurações de integração com Facebook Lead Ads",
    description: "Configure e gere o endereço único de integração com cada etapa do funil.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Integração: Recebimento de leads do Facebook Lead Ads",
            show: ".*",
        },
    ],
}

export default [
    {
        name: "integrations.facebookAds",
        path: "integrations/facebookads",
        component: () => import("@/pages/integrations/facebookAds/FacebookAdsIndex"),
        meta: FACEBOOK_META,
        children: [
            {
                name: "integrations.facebookAds.create",
                path: "integrations/facebookads/create",
                component: () => import("@/pages/integrations/facebookAds/FacebookAdsCreate"),
            },
            {
                name: "integrations.facebookAds.edit",
                path: "integrations/facebookads/:id/edit",
                props: true,
                component: () => import("@/pages/integrations/facebookAds/FacebookAdsEdit"),
            },
        ],
    },
]

