import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.segments",
        path: "administration/trial/segments",
        component: () => import("@/pages/administration/AdministrationSegmentsIndex"),
        meta: {
            pageTitle: "Segmentos | Administração",
            title: "Gerenciamento de segmentos",
            description: "Cadastre os segmentos e vincule a uma conta modelo para a criação de contas trial.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Segmentos",
                    show: ".*",
                },
            ],
        },
    },
]
