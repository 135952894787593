
export default [
    {
        name: "invoice.payment",
        path: "invoice/payment/:hash",
        component: () => import("@/pages/account/invoice/InvoicePayment"),
        meta: {
            isPublic: true,
        },
    },
]
