import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "versions.index",
        path: "settings/versions",
        components: {
            default: () => import("@/pages/versions/VersionsIndex"),
        },
        meta: {
            title: "Versões do sistema",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Versões do sistema",
                    show: ".*",
                },
            ],
        },
    },
]
