import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const importsIndex = {
    component: () => import("@/pages/importsSettings/ImportsIndex"),
    meta: {
        title: "Importações",
        description: "Faça novas importações. Na aba de históricos, consulte importações realizadas ou em andamento.",
        accessPermission: "G1",
        breadcrumb: [
            ...SettingsBreadcrumbs,
            {
                label: "Importações",
                show: ".*",
            },
        ],
    },
}

const importsIndexBreadcrumbs = [
    ...SettingsBreadcrumbs,
    {
        label: "Importações",
        href: "/v2/settings/imports",
        show: ".*",
    },
]

export default [
    {
        name: "imports.index",
        path: "settings/imports",
        ...importsIndex,
    },
    {
        name: "imports.history",
        path: "settings/imports/history",
        ...importsIndex,
    },
    {
        name: "imports.deals",
        path: "settings/imports/deals",
        redirect: { name: "importsV3.new.deal" },
        meta: {
            pageTitle: "Oportunidades | Importações",
            title: "Importação de oportunidades",
            showBetaLabel: true,
            accessPermission: "G1",
            breadcrumb: [
                ...importsIndexBreadcrumbs,
                {
                    label: "Oportunidades",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "imports.persons",
        path: "settings/imports/persons",
        redirect: { name: "importsV3.new.person" },
        component: () => import("@/pages/importsSettings/components/importModules/ImportPersons"),
        meta: {
            pageTitle: "Pessoas | Importações",
            title: "Importação de pessoas",
            showBetaLabel: true,
            accessPermission: "G1",
            breadcrumb: [
                ...importsIndexBreadcrumbs,
                {
                    label: "Pessoas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "imports.companies",
        path: "settings/imports/companies",
        redirect: { name: "importsV3.new.company" },
        component: () => import("@/pages/importsSettings/components/importModules/ImportCompanies"),
        meta: {
            pageTitle: "Empresas | Importações",
            title: "Importação de empresas",
            showBetaLabel: true,
            accessPermission: "G1",
            breadcrumb: [
                ...importsIndexBreadcrumbs,
                {
                    label: "Empresas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "imports.users",
        path: "settings/imports/users",
        component: () => import("@/pages/importsSettings/components/importModules/ImportUsers"),
        meta: {
            pageTitle: "Usuários | Importações",
            title: "Importação de usuários",
            accessPermission: "G1",
            breadcrumb: [
                ...importsIndexBreadcrumbs,
                {
                    label: "Usuários",
                    show: ".*",
                },
            ],
        },
    },
]
