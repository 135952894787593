import { getStore } from "@/store"
import { computed, watch } from "vue"

const currentUser = computed(() => getStore().getters["auth/currentUser"])
let listeners = new Map()
let listenersBackup = new Map()
let worker = null

export default function usePushNotification() {
    watch(currentUser, (value, oldValue) => {
        if (value && oldValue.value === undefined) {
            startPusherWorker()
        }
    })

    function subscribe(event, callback) {
        if (listeners.has(event)) {
            listeners.get(event).push(callback)
            return
        }

        listeners.set(event, [callback])
    }

    function unsubscribe(event, callback) {
        if (listeners.has(event)) {
            const callbacks = listeners.get(event)
            const indexOf = callbacks.indexOf(callback)
            listeners.get(event).splice(indexOf, 1)
        }
    }

    function restoreListenersBackup() {
        if (!listenersBackup.size) {
            return
        }

        listeners = new Map(listenersBackup)
        listenersBackup.clear()
    }

    function unsubscribeAll() {
        if (!listeners.size) {
            return
        }

        listenersBackup = new Map(listeners)
        listeners.clear()
    }

    function hasListeners() {
        return !!listeners.size
    }

    return {
        subscribe,
        unsubscribe,
        restoreListenersBackup,
        unsubscribeAll,
        hasListeners,
    }
}

function startPusherWorker() {
    if (!currentUser?.value || worker || typeof SharedWorker === "undefined") {
        return
    }

    worker = new SharedWorker(new URL("@/workers/PusherWorker.js", import.meta.url), { name: "PusherWorker", type: "module" })
    worker.port.postMessage({ channelName: `users.${currentUser.value.id}` })

    worker.port.onmessage = (event) => {
        const eventData = event.data
        const callbacks = listeners.get(eventData.eventName)

        if (!callbacks?.length) {
            return
        }

        for (const callback of callbacks) {
            callback(eventData)
        }
    }

    worker.onerror = function () {
        worker.port.close()
    }

    worker.port.start()

    window.addEventListener("beforeunload", function () {
        worker.port.postMessage({ closePort: true })
    })
}
