import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "segments.index",
        path: "settings/segments",
        component: () => import("@/pages/segmentsSettings/SegmentsIndex"),
        meta: {
            title: "Segmentos de negócio",
            description: null,
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Segmentos de negócio",
                    show: ".*",
                },
            ],
        },
    },
]
