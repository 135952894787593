import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "commission-rules.index",
        path: "settings/commission-rules",
        component: () => import("@/pages/commissionRules/CommissionRulesIndex"),
        meta: {
            title: "Regras de comissões",
            description: "Gerencie as regras de comissões.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Regras de comissões",
                    show: ".*",
                },
            ],
        },
    },
]
