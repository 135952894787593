import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const routesMeta = {
    title: "Grupos econômicos",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Grupo econômicos",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/economic-groups",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "settings.economicGroupsSettings.index",
                path: "",
                component: () => import("@/pages/economicGroupsSettings/EconomicGroupsIndex"),
                meta: {
                    ...routesMeta,
                },
            },
            {
                name: "settings.economicGroupsSettings.create",
                path: "create",
                component: () => import("@/pages/economicGroupsSettings/EconomicGroupsCreate"),
                meta: {
                    ...routesMeta,
                    title: "Adicionar grupo econômico",
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            href: "/v2/settings/economic-groups",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "settings.economicGroupsSettings.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/economicGroupsSettings/EconomicGroupsEdit"),
                meta: {
                    ...routesMeta,
                    title: "Editar grupo econômico",
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            href: "/v2/settings/economic-groups",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
