
import environment from "@/shared/environment"

export default [
    {
        name: "messages.index",
        path: "messages",
        component: () => import("@/pages/messages/MessagesIndex"),
        meta: {
            title: "Mensagens",
            description: "Consulte as mensagens enviadas e se foram ou não lidas.",
            accessPermission: "G1",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Mensagens",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]
