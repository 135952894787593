export default {
    beforeMount(el, binding) {
        window.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                if (typeof binding.value === "function") {
                    binding.value(event)
                }
            }
        }
        document.body.addEventListener("click", window.event)
    },
    unmounted() {
        document.body.removeEventListener("click", window.event)
    },
}
