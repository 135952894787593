import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "activityTypes.index",
        path: "settings/activity-types",
        component: () => import("@/pages/activityTypesSettings/ActivityTypesIndex"),
        meta: {
            title: "Tipos de atividade",
            description: "Gerencie os tipos de atividade disponíveis nas agendas.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Tipos de atividade",
                    show: ".*",
                },
            ],
        },
    },
]
