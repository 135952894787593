import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const meta = {
    title: "Ações em massa",
    description: "Faça ações em massa em suas oportunidades.",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Ação em massa",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/bulk-actions",
        name: "bulkActions.index",
        component: () => import("@/pages/bulkActions/BulkActionsIndex"),
        meta,
        children: [
            {
                name: "bulkActions.main",
                path: "",
                component: () => import("@/pages/bulkActions/BulkActionsMain"),
                meta,
            },
            {
                name: "bulkActions.history",
                path: "history",
                component: () => import("@/pages/bulkActions/BulkActionsHistory"),
                meta,
            },
        ],
    },
]
