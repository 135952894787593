import state from "@/store/customConfig/state"
import mutations from "@/store/customConfig/mutations"
import * as actions from "@/store/customConfig/actions"
import * as getters from "@/store/customConfig/getters"

const module = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default module
