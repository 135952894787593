import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

const routesMeta = {
    pageTitle: "Blocos de código prontos",
    title: "Blocos de código prontos",
    accessPermission: "Administrador",
    breadcrumb: [
        ...AdministrationBreadcrumbs,
    ],
}

export default [
    {
        path: "administration/translate-variables/snippets",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "admininstation.translate-variables.snippets.index",
                path: "",
                component: () => import("@/pages/administration/translateVariables/snippet/TranslateSnippetsIndex"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Blocos prontos",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "admininstation.translate-variables.snippets.create",
                path: "create",
                component: () => import("@/pages/administration/translateVariables/snippet/TranslateSnippetsCreate"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Blocos prontos",
                            href: "/v2/administration/translate-variables/snippets",
                            show: ".*",
                        },
                        {
                            label: "Novo",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "admininstation.translate-variables.snippets.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/administration/translateVariables/snippet/TranslateSnippetsEdit"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Blocos prontos",
                            href: "/v2/administration/translate-variables/snippets",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
