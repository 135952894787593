import { USER_PERMISSION_C1 } from "@/shared/variables/UserPermissionVariables"

export default [
    {
        name: "productivityRanking.index",
        path: "productivity-ranking",
        components: {
            default: () => import("@/pages/reports/rankings/ProductivityRankingIndex"),
        },
        meta: {
            pageTitle: "Ranking dinâmico de produtividade",
            title: "Ranking dinâmico de produtividade",
            mixpanelTrackOrigin: "Ranking dinâmico de produtividade",
            accessPermission: USER_PERMISSION_C1,
        },
    },
]
