<template>
    <div>
        <pipe-alert
            title="Aguardando liberação do cadastro."
            type="alert"
        >
            Seu cadastro está pendente de aprovação. Entre em contato com o gestor para liberar o seu acesso.
        </pipe-alert>
    </div>
</template>

<script>
import PipeAlert from "@/components/v2/PipeAlert"

export default {
    name: "PendingApprove",
    components: {
        PipeAlert,
    },
}
</script>
