import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const dealSettingsMeta = {
    pageTitle: "Cadastro de oportunidades",
    title: "Campos em cadastro de oportunidades",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Campos em cadastro de oportunidades",
            show: ".*",
        },
    ],
}

export default [
    {
        name: "dealSettings.index",
        path: "settings/deal",
        component: () => import("@/pages/dealSettings/DealSettingsIndex"),
        meta: dealSettingsMeta,
    },
    {
        name: "dealSettings.form",
        path: "settings/deal/form/:configId?",
        component: () => import("@/pages/dealSettings/DealSettingsForm"),
        meta: dealSettingsMeta,
    },
]
