import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.proposal.template",
        path: "settings/proposal/template",
        component: () => import("@/pages/proposalTemplateSettings/ProposalTemplateIndex"),
        meta: {
            title: "Template de proposta",
            accessPermission: "G1",
            description: "Configure templates de proposta para ter propostas com dados pré-carregados que sirvam de base para serem desenvolvidas, ganhando maior eficiência em suas vendas.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Template de propostas",
                    show: ".*",
                },
            ],
        },
    },
]
