import environment from "@/shared/environment"

export default [
    {
        name: "teamGroups",
        path: "team-groups",
        component: () => import("@/pages/teamGroups/TeamGroupsIndex"),
        meta: {
            title: "Grupos de equipes",
            description: "Grupos de equipes que você faz parte.",
            accessPermission: "C1",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    extebarnal: true,
                },
                {
                    label: "Grupos de equipes",
                    show: ".*",
                },
            ],
        },
    },
]
