<template>
    <transition
        name="spinner-fade"
        appear
    >
        <div
            v-show="visible"
            :class="[
                'spinner-loader',
                {
                    opaque,
                    'spinner-loader--fullscreen': fullscreen,
                },
                customSpinnerClass
            ]"
        >
            <img :src="$static.loadingRedFast">
        </div>
    </transition>
</template>

<script>
export default {
    name: "SpinnerLoader",
    props: {
        visible: {
            default: false,
            type: Boolean,
        },
        opaque: {
            type: Boolean,
            default: null,
        },
        customSpinnerClass: {
            type: String,
            default: null,
        },
        fullscreen: {
            type: Boolean,
            default: true,
        },
        logoSize: {
            type: String,
            default: "200px",
        },
    },
}
</script>

<style lang="less" scoped>
.spinner-fade-enter-active, .spinner-fade-leave-active {
    transition: opacity 150ms;
}
.spinner-fade-enter-from, .spinner-fade-leave-to {
    opacity: 0;
}

.spinner-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;

    &.spinner-loader--fullscreen {
        position: fixed;
        margin: auto;
        top: -50px;
        left: 0;
        bottom: 0;
        right: 0;

        &:before {
            position: fixed;
        }
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(20, 20, 20, 0.2);
    }

    img {
        width: v-bind('logoSize');
        margin: auto;
        user-select: none;
    }

    &.opaque:before {
        background-color: rgba(30, 30, 30, 1);
    }
}
</style>
