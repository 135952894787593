import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.dataList.index",
        path: "settings/data-list",
        components: {
            default: () => import("@/pages/dataListSettings/DataListSettingsIndex"),
        },
        meta: {
            title: "Lista de dados customizados",
            accessPermission: "G1",
            description: "Crie e gerencie listas de dados customizados para oportunidades, empresas e pessoas.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Lista de dados customizados",
                    show: ".*",
                },
            ],
        },
    },
]
