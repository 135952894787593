import ClickOutside from "@/directives/clickOutside"
import { vMaska } from "maska"
import { VTooltip, VClosePopper } from "floating-vue"

// register all directives
const directives = (app) => {
    app.directive("tooltip", VTooltip)
    app.directive("close-popper", VClosePopper)
    app.directive("click-outside", ClickOutside)
    app.directive("maska", vMaska)
}

export default directives
