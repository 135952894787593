export async function generateHmacHash(key, data) {
    const encoder = new TextEncoder()
    const encodedData = encoder.encode(data)
    const encodedKey = encoder.encode(key)

    const cryptoKey = await window.crypto.subtle.importKey(
        "raw",
        encodedKey,
        {name: "HMAC", hash: {name: "SHA-256"}},
        false,
        ["sign"]
    )
    const signature = await window.crypto.subtle.sign("HMAC", cryptoKey, encodedData)

    const signatureBytes = new Uint8Array(signature)
    return signatureBytes.reduce((acc, byte) => {
        let hex = byte.toString(16)
        if (hex.length === 1) {
            return acc + "0" + hex
        }

        return acc + hex
    }, "")
}
