import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const automationsIndexBreadcrumbs = [
    ...SettingsBreadcrumbs,
    {
        label: "Ações automáticas",
        href: "/v2/automations",
        show: ".*",
    },
]

export default [
    {
        name: "automations.index",
        path: "automations",
        component: () => import("@/pages/automations/AutomationsIndex"),
        meta: {
            title: "Ações automáticas",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Ações automáticas",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "automations.form",
        path: "automations/form/:automationId?",
        alias: ["automations/form-superlogica"],
        component: () => import("@/pages/automations/AutomationsForm"),
        meta: {
            pageTitle: "Ações automáticas",
            accessPermission: "G1",
            breadcrumb: [
                ...automationsIndexBreadcrumbs,
                {
                    label: "Nova ação automática",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "automations.duplicate",
        path: "automations/duplicate/:automationId",
        component: () => import("@/pages/automations/AutomationsForm"),
        meta: {
            pageTitle: "Ações automáticas",
            accessPermission: "G1",
            breadcrumb: [
                ...automationsIndexBreadcrumbs,
                {
                    label: "Duplicar ação automática",
                    href: "",
                    show: ".*",
                },
            ],
            buttons: null,
        },
    },
    {
        name: "automations.logs",
        path: "automations/logs/:automationId?",
        component: () => import("@/pages/automations/AutomationsLogs"),
        meta: {
            title: "Logs de ação automática",
            pageTitle: "Logs | Ações automáticas",
            accessPermission: "G1",
            breadcrumb: [
                ...automationsIndexBreadcrumbs,
                {
                    label: "Logs de ação automática",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]
