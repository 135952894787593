import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "webhooks",
        path: "settings/webhooks/:webhook_id?",
        component: () => import("@/pages/webhookSettings/WebhookSettingsIndex"),
        meta: {
            title: "Webhooks",
            accessPermission: "G1",
            description: "Gerencie webhooks.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Webhooks",
                    show: ".*",
                },
            ],
        },
    },
]
