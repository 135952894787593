import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.planManagement",
        path: "administration/plans/management/:item_id?",
        components: {
            default: () => import("@/pages/administration/PlanManagement"),
        },
        meta: {
            pageTitle: "Planos comerciais | Administração",
            title: "Planos comerciais",
            description: "Controle de planos comerciais.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Planos comerciais",
                    show: ".*",
                },
            ],
        },
    },
]
