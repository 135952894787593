import { defineAsyncComponent } from "vue"

export const FILTER_ENTITY_DEAL =    1
export const FILTER_ENTITY_COMPANY = 2
export const FILTER_ENTITY_PERSON =  3

export const filterEntity = {
    [FILTER_ENTITY_DEAL]:    "deal",
    [FILTER_ENTITY_COMPANY]: "company",
    [FILTER_ENTITY_PERSON]:  "person",
}

export const filterEntityForm = {
    [FILTER_ENTITY_DEAL]:    () => {},
    [FILTER_ENTITY_COMPANY]: defineAsyncComponent(() => import("@/pages/companies/components/CompanyFiltersForm")),
    [FILTER_ENTITY_PERSON]:  defineAsyncComponent(() => import("@/pages/persons/components/PersonFiltersForm")),
}

export const noFilterText = {
    [FILTER_ENTITY_DEAL]:    "Todas as oportunidades",
    [FILTER_ENTITY_COMPANY]: "Todas as empresas",
    [FILTER_ENTITY_PERSON]:  "Todas as pessoas",
}
