export default {
    ["SET_TOKEN"](state, value) {
        state.token = value
    },
    ["SET_REFRESH"](state, value) {
        state.refreshToken = value
    },
    ["SET_USER"](state, value) {
        state.user = value
    },
    ["SET_ACL"](state, value) {
        state.acl = value
    },
}
