import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const routesMeta = {
    title: "Templates de e-mail",
    description: "Gerencie os templates de e-mail da sua conta.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Templates de e-mail",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/templates-email",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "settings.templatesEmailSettings.index",
                path: "",
                component: () => import("@/pages/templatesEmailSettings/TemplatesEmailIndex"),
                meta: {
                    ...routesMeta,
                },
            },
            {
                name: "settings.templatesEmailSettings.create",
                path: "create",
                component: () => import("@/pages/templatesEmailSettings/TemplatesEmailCreate"),
                meta: {
                    title: "Novo template de e-mail",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Templates de e-mail",
                            href: "/v2/settings/templates-email/",
                            show: ".*",
                        },
                        {
                            label: "Novo",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "settings.templatesEmailSettings.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/templatesEmailSettings/TemplatesEmailEdit"),
                meta: {
                    title: "Editar template de e-mail",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Template de e-mail",
                            href: "/v2/settings/templates-email/",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
