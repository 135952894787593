import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        path: "settings/distribution",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "settings.distribution",
                path: "",
                component: () => import("@/pages/distributionSettings/DistributionSettingsIndex"),
                meta: {
                    title: "Distribuição de leads",
                    accessPermission: "G1",
                    description: "Configure listas de distribuição de leads.",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Distribuição de leads",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "settings.distribution.create",
                path: "create",
                component: () => import("@/pages/distributionSettings/DistributionSettingsCreate"),
                meta: {
                    title: "Criar distribuição de leads",
                    accessPermission: "G1",
                    description: "Configure listas de distribuição de leads.",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Distribuição de leads",
                            href: "/v2/settings/distribution",
                            show: ".*",
                        },
                        {
                            label: "Novo",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "settings.distribution.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/distributionSettings/DistributionSettingsEdit"),
                meta: {
                    title: "Criar distribuição de leads",
                    accessPermission: "G1",
                    description: "Configure listas de distribuição de leads.",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Distribuição de leads",
                            href: "/v2/settings/distribution",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
