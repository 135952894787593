import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.item.characteristics",
        path: "settings/item/characteristics",
        components: {
            default: () => import("@/pages/itemCharacteristicsSettings/ItemCharacteristicsSettingsIndex"),
        },
        meta: {
            title: "Características",
            accessPermission: "G1",
            description: "Gerencie as características de produtos, serviços e MRR.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Características",
                    show: ".*",
                },
            ],
        },
    },
]
