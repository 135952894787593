import environment from "@/shared/environment"
import { getStore } from "@/store"

export default [
    {
        name: "forecast.index",
        path: "forecast",
        component: () => import("@/pages/forecast/ForecastIndex"),
        beforeEnter: onlyAllowedForecast,
        meta: {
            title: "Forecast",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Forecast",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedForecast(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.allows_forecast) {
        window.location = `${environment.APP_YII}/pipeline`
    }

    next()
}
