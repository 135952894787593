import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.account.management",
        path: "administration/account/management",
        components: {
            default: () => import("@/pages/administration/AccountManagementIndex"),
        },
        meta: {
            pageTitle: "Gerenciar Contas | Administração",
            title: "Gerenciar Contas",
            description: "Gerenciar Contas",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Gerenciar Contas",
                    show: ".*",
                },
            ],
        },
    },
]
