/* Salva os tokens em cookie */
export const setTokens = (accessToken, refreshToken) => {
    if (accessToken && refreshToken) {
        /* Falta ;secure */
        document.cookie = `access_token=${accessToken}; path=/; SameSite=lax;`
        document.cookie = `refresh_token=${refreshToken}; path=/; SameSite=lax;`
    }
}

/* Pega os tokens no cookie */
export const getTokens = () => {
    const accessCookie = "access_token=",
        refreshCookie = "refresh_token=",
        allCookies = decodeURIComponent(document.cookie).split(";"),
        accessToken = allCookies.find(cookie => cookie.match(accessCookie)),
        refreshToken = allCookies.find(cookie => cookie.match(refreshCookie))

    if (accessToken || refreshToken) {
        const tokens = [accessToken, refreshToken].reduce((allTokens, token) => {
            if (token) {
                const currentToken = token.trim().split("=")
                allTokens[currentToken[0]] = currentToken[1]
            }

            return allTokens
        }, {})

        return tokens
    }

    return false
}

/* Limpa os tokens */
export const clearTokens = () => {
    document.cookie = "access_token=; path=/; SameSite=lax; expires=Thu, 01 Jan 1970 00:00:01 GMT"
    document.cookie = "refresh_token=; path=/; SameSite=lax; expires=Thu, 01 Jan 1970 00:00:01 GMT"
}

export const isDoubleSession = () => {
    const allCookies = decodeURIComponent(document.cookie).split(";")
    const doubleSessionCookie = allCookies.find(cookie => cookie?.trim()?.startsWith("double_session="))

    if (doubleSessionCookie) {
        const [, value] = doubleSessionCookie.split("=")

        return !!value
    }

    return false
}
