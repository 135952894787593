import environment from "@/shared/environment"
import { TYPE_ARCHIVED, TYPE_DRAFT, TYPE_RECEIVED, TYPE_SENT } from "@/shared/variables/EmailVariables"

const META = {
    title: "E-mails",
    breadcrumb: [
        {
            label: "Home",
            href: environment.APP_YII,
            show: ".*",
            external: true,
        },
        {
            label: "E-mails",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "emails",
        component: () => import("@/pages/emails/EmailsIndex"),
        meta: META,
        children: [
            {
                name: "emails.index",
                path: "",
                redirect: { name: "emails.inbox" },
            },
            {
                name: "emails.inbox",
                path: "inbox",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: TYPE_RECEIVED,
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
            {
                name: "emails.sent",
                path: "sent",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: TYPE_SENT,
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
            {
                name: "emails.important",
                path: "important",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: "important",
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
            {
                name: "emails.drafts",
                path: "drafts",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: TYPE_DRAFT,
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
            {
                name: "emails.archieved",
                path: "archieved",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: TYPE_ARCHIVED,
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
            {
                name: "emails.trash",
                path: "trash",
                component: () => import("@/pages/emails/components/EmailsList"),
                props: {
                    type: "trash",
                    currentUserIsTheInitial: true,
                },
                meta: META,
            },
        ],
    },
]
