import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        path: "settings/domains",
        component: () => import("@/layouts/EmptyRouterLayout"),
        beforeEnter(to, from, next) {
            const currentUser = getStore().getters["auth/currentUser"]
            if (!currentUser.account.allows_landing_page) {
                return next({ name: "settings.index" })
            }

            next()
        },
        children: [
            {
                name: "domain.index",
                path: "",
                component: () => import("@/pages/domains/DomainsIndex"),
                meta: {
                    title: "Domínios",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Domínios",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
