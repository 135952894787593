import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import * as customConfig from "@/shared/variables/CustomConfigVariables"

import { getStore } from "@/store"

function protectExportAccess(customConfigName, customConfigScopeName) {
    return function (to, from, next) {
        const store = getStore()
        const hasPermission = store.getters["auth/userCanExport"](customConfigName, customConfigScopeName)

        if (!hasPermission) {
            return next({ name: "notfound", query: { errorId: 403 } })
        }

        return next()
    }
}

const exportsIndex = {
    component: () => import("@/pages/exportsSettings/ExportsIndex"),
    meta: {
        title: "Exportações",
        description: "Faça novas exportações. Na aba de históricos, consulte exportações realizadas ou em andamento.",
        breadcrumb: [
            ...SettingsBreadcrumbs,
            {
                label: "Exportações",
                show: ".*",
            },
        ],
    },
}

export default [
    {
        name: "exports.index",
        path: "settings/exports",
        ...exportsIndex,
    },
    {
        name: "exports.history",
        path: "settings/exports/history",
        ...exportsIndex,
    },
    {
        name: "exports.deals",
        path: "settings/exports/deals",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportDeals"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_DEALS, customConfig.SHOW_SCOPE_EXPORT_DEALS),
        meta: {
            pageTitle: "Oportunidades | Exportações",
            title: "Exportar oportunidades",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Oportunidades",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.persons",
        path: "settings/exports/persons",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportPersons"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_PERSONS, customConfig.SHOW_SCOPE_EXPORT_PERSONS),
        meta: {
            pageTitle: "Pessoas | Exportações",
            title: "Exportar pessoas",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Pessoas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.proposals",
        path: "settings/exports/proposals",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportProposals"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_PROPOSALS, customConfig.SHOW_SCOPE_EXPORT_PROPOSALS),
        meta: {
            pageTitle: "Propostas | Exportações",
            title: "Exportar propostas",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Propostas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.activities",
        path: "settings/exports/activities",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportActivities"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_ACTIVITIES, customConfig.SHOW_SCOPE_EXPORT_ACTIVITIES),
        meta: {
            pageTitle: "Atividades | Exportações",
            title: "Exportar atividades",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Atividades",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.company",
        path: "settings/exports/company",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportCompanies"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_COMPANIES, customConfig.SHOW_SCOPE_EXPORT_COMPANIES),
        meta: {
            pageTitle: "Empresas | Exportações",
            title: "Exportar empresas",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Empresas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.regions",
        path: "settings/exports/regions",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportRegions"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_REGIONS, customConfig.SHOW_SCOPE_EXPORT_REGIONS),
        meta: {
            pageTitle: "Regiões | Exportações",
            title: "Exportar regiões",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Regiões",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.companyBondPerson",
        path: "settings/exports/company-bond-person",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportCompanyBondPerson"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_PERSONS_LINKED_COMPANIES, customConfig.SHOW_SCOPE_EXPORT_PERSONS_LINKED_COMPANIES),
        meta: {
            pageTitle: "Pessoas vinculadas a empresas | Exportações",
            title: "Exportar pessoas vinculadas a empresas",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Pessoas vinculadas a empresas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.items",
        path: "settings/exports/items",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportItems"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_ITEMS, customConfig.SHOW_SCOPE_EXPORT_ITEMS),
        meta: {
            title: "Exportar produtos",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Produtos",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.calls",
        path: "settings/exports/calls",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportCalls"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_CALLS, customConfig.SHOW_SCOPE_EXPORT_CALLS),
        meta: {
            pageTitle: "Ligações | Exportações",
            title: "Exportar ligações",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Ligações",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.dataLists",
        path: "settings/exports/data-lists",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportDataLists"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_DATALIST, customConfig.SHOW_SCOPE_EXPORT_DATALIST),
        meta: {
            pageTitle: "Listas de dados | Exportações",
            title: "Exportar Listas de dados",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Listas de dados",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "exports.dealItems",
        path: "settings/exports/deal-items",
        component: () => import("@/pages/exportsSettings/components/exportModules/ExportDealItems"),
        beforeEnter: protectExportAccess(customConfig.SHOW_EXPORT_DEAL_PRODUCTS, customConfig.SHOW_SCOPE_EXPORT_DEAL_PRODUCTS),
        meta: {
            pageTitle: "Itens em oportunidades | Exportações",
            title: "Exportar itens vinculados em oportunidades",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Exportações",
                    href: "/v2/settings/exports",
                    show: ".*",
                },
                {
                    label: "Itens vinculados em oportunidades",
                    show: ".*",
                },
            ],
        },
    },
]
