import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "paymentMethods.index",
        path: "settings/payment-methods",
        component: () => import("@/pages/paymentMethodsSettings/PaymentMethodsIndex"),
        meta: {
            title: "Formas de pagamento",
            description: "Gerencie as formas de pagamento que serão utilizadas nas propostas das oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Formas de pagamento",
                    show: ".*",
                },
            ],
        },
    },
]
