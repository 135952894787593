export const openQuickViewer = ({ commit }, { entity, entityId }) => {
    commit("SET_ENTITY", entity)
    commit("SET_ENTITY_ID", entityId)
    commit("SET_IS_SHOWING", true)
}

export const closeQuickViewer = ({ commit }) => {
    commit("SET_IS_SHOWING", false)
}

export const setEntityData = ({ commit }, data) => {
    commit("SET_ENTITY_DATA", data)
}
