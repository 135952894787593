import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.integrations",
        path: "administration/integrations",
        component: () => import("@/pages/administration/IntegrationsIndex"),
        meta: {
            pageTitle: "Integrações | Administração",
            title: "Administração de integrações",
            description: "Organize as categorias e integrações disponíveis para clientes.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Integrações",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "administration.integrations.categories",
        path: "administration/integrations/categories",
        component: () => import("@/pages/administration/CategoriesIndex"),
        meta: {
            pageTitle: "Categorias de integrações | Administração",
            title: "Administração de categorias de integrações",
            description: "Organize as categorias de integrações.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Integrações",
                    href: "/v2/administration/integrations",
                    show: ".*",
                },
                {
                    label: "Categorias",
                    show: ".*",
                },
            ],
        },
    },
]
