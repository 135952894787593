<template>
    <transition
        name="fadeTransition"
        appear
    >
        <div
            v-if="thirdPartyData.auth_url"
            class="sso-form"
        >
            <img
                v-if="thirdPartyData.account.logo"
                :src="thirdPartyData.account.logo"
                :alt="thirdPartyData.account.name"
                class="sso-form__logo"
            >
            <a
                class="p-btn p-btn--primary sso-form__submit"
                :href="`${thirdPartyData.auth_url}?redirect_url=${yiiUrl}/v2/login/${uri}/redirect`"
            >
                Entrar com sua conta {{ thirdPartyData.account.name }}
            </a>
        </div>
        <div
            v-else
            class="sso-form__loader"
        >
            <span class="p-spin-loader fa fa-refresh" />
        </div>
    </transition>
</template>

<script>
import axios from "axios"
import environment from "@/shared/environment"

export default {
    data() {
        return {
            thirdPartyData: {
                account: {
                    logo: null,
                    name: null,
                },
                auth_url: null,
            },
        }
    },
    computed: {
        apiUrl() {
            return environment.APP_API
        },
        yiiUrl() {
            return environment.APP_YII
        },
        uri() {
            return this.$route.params.uri
        },
    },
    created() {
        axios.get(`${this.apiUrl}/oauth/uri/${this.uri}`)
            .then(({ data }) => {
                this.thirdPartyData = data.data
                if (this.token) {
                    this.checkToken(this.token)
                }
            })
            .catch(() => this.$router.push({ name: "login.auth" }))
    },
    methods: {
        defaultLogin() {
            localStorage.removeItem("preferedLogin")
            window.location = `${this.yiiUrl}/login`
        },
    },
}
</script>

<style lang="less">
.sso-form {
    text-align: center;

    &__logo {
        display: inline-block;
        max-width: 250px;
        margin-bottom: 32px;
    }

    &__submit {
        margin-bottom: 16px;
    }
}

.fadeTransition-enter-active, .fadeTransition-leave-active {
  transition: opacity 2s;
}
.fadeTransition-enter-from, .fadeTransition-leave-to {
  opacity: 0;
}
</style>
