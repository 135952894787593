import ExactSpotter from "@/router/routes/integrations/ExactSpotter"
import OpenAi from "@/router/routes/integrations/OpenAi"
import FacebookAds from "@/router/routes/integrations/FacebookAds"
import Rdstation from "@/router/routes/integrations/Rdstation"
import environment from "@/shared/environment"

export default [
    {
        name: "integrations.index",
        path: "integrations",
        components: {
            default: () => import("@/pages/integrations/IntegrationsIndex"),
        },
        meta: {
            title: "Central de integrações",
            accessPermission: "G1",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Central de integrações",
                    show: ".*",
                },
            ],
            showToken: true,
        },
    },

    ...ExactSpotter,
    ...OpenAi,
    ...FacebookAds,
    ...Rdstation,
]
