import environment from "@/shared/environment"

export default [
    {
        name: "pipedrive.index",
        path: "migrators/pipedrive",
        component: () => import("@/pages/migrators/pipedrive/PipedriveIndex"),
        meta: {
            title: "Migrador de conta: Pipedrive",
            description: "Realize a migração automática dos dados de sua conta Pipedrive para a sua conta PipeRun.",
            accessPermission: "G1",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Ajustes e configurações",
                    show: ".*",
                    href: "/v2/settings",
                },
                {
                    label: "Integrações",
                    show: ".*",
                    href: "/integracao",
                    external: true,
                },
                {
                    label: "Pipedrive",
                    show: ".*",
                },
            ],
        },
    },
]
