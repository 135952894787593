import App from "@/App"
import router, { configureRouter, setAppInstance } from "@/router"
import { getStore } from "@/store"
import { createApp } from "vue"
import directives from "@/directives"
import "@/assets/styles/index"
import { load as loadPlugins } from "@/boot/plugins"

const app = createApp(App)

directives(app)
loadPlugins(app)
setAppInstance(app)
configureRouter()

app.use(getStore())
app.use(router)

app.mount("#app")
