import environment from "@/shared/environment"

export default [
    {
        label: "Home",
        href: environment.APP_YII,
        show: ".*",
        external: true,
    },
    {
        label: "Administração",
        href: "/v2/administration",
        show: ".*",
    },
]
