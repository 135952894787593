// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
import state from "@/store/currencies/state"
import mutations from "@/store/currencies/mutations"
import * as actions from "@/store/currencies/actions"
import * as getters from "@/store/currencies/getters"

const module = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default module
