import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

const routesMeta = {
    pageTitle: "Webhook | Administração",
    title: "Templates de formatação para Webhook",
    description: "Criação e edição de templates que ficarão disponíveis na ação automática de Webhook - Enviar oportunidade para URL.",
    accessPermission: "Administrador",
    breadcrumb: [
        ...AdministrationBreadcrumbs,
    ],
}

export default [
    {
        path: "administration/expressionTemplates",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "admininstation.expressionTemplates.index",
                path: "",
                component: () => import("@/pages/administration/expressionTemplates/ExpressionTemplatesIndex"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Webhook",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "admininstation.expressionTemplates.create",
                path: "create",
                component: () => import("@/pages/administration/expressionTemplates/ExpressionTemplatesCreate"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Webhook",
                            href: "/v2/administration/expressionTemplates",
                            show: ".*",
                        },
                        {
                            label: "Template",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "admininstation.expressionTemplates.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/administration/expressionTemplates/ExpressionTemplatesEdit"),
                meta: {
                    ...routesMeta,
                    breadcrumb: [
                        ...routesMeta.breadcrumb,
                        {
                            label: "Webhook",
                            href: "/v2/administration/expressionTemplates",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
