<template>
    <span
        class="pipe-icon"
        :class="['icon', 'fa', 'fa-' + i]"
        :style="{ color: color }"
    />
</template>

<script>
export default {
    props: {
        i: {
            type: String,
            default: "gear",
        },
        color: {
            type: String,
            default: null,
        },
    },
}
</script>
