import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.users",
        path: "administration/users",
        components: {
            default: () => import("@/pages/administration/UsersIndex"),
        },
        meta: {
            pageTitle: "Usuários | Administração",
            title: "Usuários",
            description: "Administração de usuários",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Usuários",
                    show: ".*",
                },
            ],
        },
    },
]
