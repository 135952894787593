import Swal from "sweetalert2"

export default function useModalNotification() {
    function success(title, info = "", btnTextConfirm = "OK", btnTextCancel = "", reverseButtons = true, bodyContentSwal = "", didOpen = {}, preConfirm = {}) {
        const html = `
            <div class="centered-container">
                <div class="icon-styled icon-styled-success">
                    <i class="icon-success fa fa-check" aria-hidden="true"></i>
                    <br>
                </div>
                <p class="title">${title}</p>
                <p class="info">${info}</p>
                <div>${bodyContentSwal}</div>
            </div>
        `

        const config = {
            customClass: {
                popup: `swal-custom-styled-success ${btnTextCancel ? "" : "no-cancel-btn"}`,
                confirmButton: "btn-swal-success",
                cancelButton: !btnTextCancel ? null : "btn-swal-white",
            },
            confirmButtonText: btnTextConfirm,
            showCancelButton: !!btnTextCancel,
            cancelButtonText: btnTextCancel,
            reverseButtons: !!btnTextCancel && reverseButtons,
            ...didOpen,
            ...preConfirm,
        }

        return createModal(html, config)
    }

    function danger(title, info = "", icon = "remove", btnTextConfirm = "OK", btnTextCancel = "", reverseButtons = true) {
        const html = `
            <div class="centered-container">
                <div class="icon-styled icon-styled-danger">
                    <i class="icon-danger fa fa-${icon}" aria-hidden="true"></i>
                    <br>
                </div>
                <p class="title">${title}</p>
                <p class="info">${info}</p>
            </div>
        `

        const config = {
            customClass: {
                popup: `swal-custom-styled-danger ${btnTextCancel ? "" : "no-cancel-btn"}`,
                confirmButton: "btn-swal-danger",
                cancelButton: !btnTextCancel ? null : "btn-swal-white",
            },
            confirmButtonText: btnTextConfirm,
            showCancelButton: !!btnTextCancel,
            cancelButtonText: btnTextCancel,
            reverseButtons: !!btnTextCancel && reverseButtons,
        }

        return createModal(html, config)
    }

    function warning(
        title,
        info = "",
        btnTextConfirm = "OK",
        btnTextCancel = "",
        reverseButtons = true,
        input = {},
        didOpen = {},
        preConfirm = {}
    ) {
        const html = `
            <div class="centered-container">
                <div class="icon-styled icon-styled-warning">
                    <i class="icon-warning fa fa-exclamation-triangle" aria-hidden="true"></i>
                    <br>
                </div>
                <p class="title">${title}</p>
                <p class="info">${info}</p>
            </div>
        `

        const config = {
            customClass: {
                popup: `swal-custom-styled-warning ${btnTextCancel ? "" : "no-cancel-btn"}`,
                confirmButton: "btn-swal-warning",
                cancelButton: !btnTextCancel ? null : "btn-swal-white",
            },
            confirmButtonText: btnTextConfirm,
            showCancelButton: !!btnTextCancel,
            cancelButtonText: btnTextCancel,
            reverseButtons: !!btnTextCancel && reverseButtons,
            ...input,
            ...didOpen,
            ...preConfirm,
        }

        return createModal(html, config)
    }

    /**
     * @param {string} title
     * @param {string} message
     * @param {string} icon
     * @returns {void}
     */
    function loading(title, message, icon = "info") {
        const html = `
            <div class="centered-container">
                <div class="icon-styled icon-styled-info">
                    <i class="icon-info fa fa-${icon}" aria-hidden="true"></i>
                    <br>
                </div>
                <p class="title">${title}</p>
                <p class="info">${message}</p>
            </div>
        `
        const config = {
            customClass: {
                popup: "swal-custom-styled-loader",
            },
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        }

        createModal(html, config).then(() => {})
    }

    function close() {
        Swal.close()
    }

    function disableConfirmOnName(name) {
        const confirmBtn = Swal.getConfirmButton()
        confirmBtn.disabled = true

        Swal.getInput().addEventListener("keyup", e => {
            confirmBtn.disabled = e.target.value !== name
        })
    }

    return {
        success,
        danger,
        warning,
        loading,
        close,
        disableConfirmOnName,
    }
}

export function createModal(html, config) {
    return Swal.fire({
        html,
        allowOutsideClick: false,
        ...config,
    })
}
