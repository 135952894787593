import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "integrations.rdstation",
        path: "integrations/rdstation",
        component: () => import("@/pages/integrations/rdstation/RdstationIndex"),
        meta: {
            pageTitle: "Integrações | RD Station",
            title: "Configurações de integração com RDStation",
            description: "Configure e gere o endereço único de integração com cada etapa do funil.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Integração: Recebimentos de leads do RDStation",
                    show: ".*",
                },
            ],
        },
    },
]

