import environment from "@/shared/environment"

const ME_META = {
    title: "Meus dados",
    description: "Gerencie seus dados de usuário e suas configurações de agenda e de e-mail.",
    breadcrumb: [
        {
            label: "Home",
            href: environment.APP_YII,
            show: ".*",
            external: true,
        },
        {
            label: "Meus dados",
            show: ".*",
        },
    ],
}

const EDIT_USER_META = {
    pageTitle: "Edição de usuário",
    title: "Edição de usuário",
    description: "Gerencie os dados de um usuário e suas configurações de agenda e de e-mail.",
    accessPermission: "G1",
    breadcrumb: [
        {
            label: "Home",
            href: environment.APP_YII,
            show: ".*",
            external: true,
        },
        {
            label: "Edição de usuário",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "me",
        component: () => import("@/pages/userSettings/UserSettingsIndex"),
        meta: ME_META,
        children: [
            {
                path: "",
                redirect: { name: "user.settings.user-data" },
            },
            {
                name: "user.settings.user-data",
                path: "user-data",
                component: () => import("@/pages/userSettings/components/UserData"),
                meta: ME_META,
            },
            {
                name: "user.settings.user-schedule",
                path: "user-schedule",
                component: () => import("@/pages/userSettings/components/UserSchedule"),
                meta: ME_META,
            },
            {
                name: "user.settings.user-emails",
                path: "user-emails",
                component: () => import("@/pages/userSettings/components/UserEmails"),
                meta: ME_META,
            },
            {
                name: "user.settings.user-configuration",
                path: "user-configuration",
                component: () => import("@/pages/userSettings/components/UserConfiguration"),
                meta: ME_META,
            },
        ],
    },
    {
        path: "user/:userId",
        component: () => import("@/pages/userSettings/UserSettingsEdit"),
        meta: EDIT_USER_META,
        children: [
            {
                name: "user.settings.edit.user-data",
                path: "user-data",
                component: () => import("@/pages/userSettings/components/UserData"),
                meta: EDIT_USER_META,
            },
            {
                name: "user.settings.edit.user-schedule",
                path: "user-schedule",
                component: () => import("@/pages/userSettings/components/UserSchedule"),
                meta: EDIT_USER_META,
            },
            {
                name: "user.settings.edit.user-emails",
                path: "user-emails",
                component: () => import("@/pages/userSettings/components/UserEmails"),
                meta: EDIT_USER_META,
            },
            {
                name: "user.settings.edit.user-configuration",
                path: "user-configuration",
                component: () => import("@/pages/userSettings/components/UserConfiguration"),
                meta: EDIT_USER_META,
            },
        ],
    },
]
