import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const meta = {
    title: "Origens e grupos de origens",
    description: "Gerencie as origens e grupos de origens de suas oportunidades.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Origens e grupos de origens",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/origins",
        meta,
        component: () => import("@/pages/originsSettings/OriginsIndex"),
        children: [
            {
                path: "",
                meta,
                name: "origins.origin",
                component: () => import("@/pages/originsSettings/components/OriginsList"),
            },
            {
                path: "originGroups",
                meta,
                name: "origins.originGroups",
                component: () => import("@/pages/originsSettings/components/OriginsGroupList"),
            },
        ],
    },
]
