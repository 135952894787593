import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "customFields.index",
        path: "custom-fields",
        component: () => import("@/pages/customFields/CustomFieldsIndex"),
        meta: {
            title: "Campos customizados",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Campos customizados",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]
