import { isArray, isEmpty } from "lodash-es"
import { USER_PERMISSION_ADMIN, USER_PERMISSION_G1 } from "@/shared/variables/UserPermissionVariables"
import * as customConfig from "@/shared/variables/CustomConfigVariables"

const PIPE_MAIN_ACCOUNT_ID = 1
const PLAN_TRIAL_ID = 9

// if has token, we assume that user is logged in our system
export const isLogged = ({ token }) => !isEmpty(token)
// get current user data
export const currentUser = ({ user }) => user
// get permissions
export const hasAdminPermission = ({ acl }) => (acl === USER_PERMISSION_ADMIN)
export const hasG1Permission = ({ acl }) => (acl === USER_PERMISSION_ADMIN || acl === USER_PERMISSION_G1)
//account
export const accountInTrial = ({ user: { account } }) => account.plan_id === PLAN_TRIAL_ID
export const isPipeMainAccount = ({ user: { account } }) => account.id === PIPE_MAIN_ACCOUNT_ID
// get tokens
export const getToken = ({ token }) => token
export const getRefreshToken = ({ refreshToken }) => refreshToken

// Configurações de escopo de usuário
export const currentUserScope = ({ user }) => user.only_yours_deals
export const userCanCreateContacts = (state, getters, rootState, rootGetters) => {
    return userHasScopeAccessContact(getters, rootGetters, customConfig.C1_CAN_CREATE_CONTACT, customConfig.C1_SCOPE_CAN_CREATE_CONTACT)
}
export const userCanEditContacts = (state, getters, rootState, rootGetters) => {
    return userHasScopeAccessContact(getters, rootGetters, customConfig.C1_CAN_EDIT_CONTACTS, customConfig.C1_SCOPE_CAN_EDIT_CONTACT)
}
export const userCanEditContactManager = (state, getters, rootState, rootGetters) => {
    return userHasScopeAccessContact(getters, rootGetters, customConfig.C1_CAN_EDIT_CONTACT_MANAGER, customConfig.C1_SCOPE_CAN_EDIT_CONTACT_MANAGER)
}

// Configurações de exportação
export function userCanExport(state, getters, rootState, rootGetters) {
    return function (customConfigName, customScopeConfigName) {
        return userHasScopeAccess(getters, rootGetters, customConfigName, customScopeConfigName)
    }
}

function userHasScopeAccess(getters, rootGetters, userConfigName, userScopeConfigName) {
    if (getters.hasG1Permission) {
        return true
    }

    const customConfigs = rootGetters["customConfig/get"]
    const userConfig = customConfigs[userConfigName]
    const userScopeConfig = customConfigs[userScopeConfigName]

    if (userConfig && isArray(userScopeConfig)) {
        const currentScope = getters.currentUserScope
        const existsWithScope = userScopeConfig.find((config) => config.scope === currentScope)

        if (existsWithScope) {
            const userId = getters.currentUser.id

            return existsWithScope.allUsers || existsWithScope.usersId.includes(userId)
        }
    }

    return false
}

function userHasScopeAccessContact(getters, rootGetters, userConfigName, userScopeConfigName) {
    const customConfigs = rootGetters["customConfig/get"]
    const userConfig = customConfigs[userConfigName]
    const userScopeConfig = String(customConfigs[userScopeConfigName])
    const currentScope = getters.currentUserScope.toString()

    return getters.hasG1Permission ||
        (userConfig && userScopeConfig.split(",").includes(currentScope))
}
