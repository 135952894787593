import view from "@/store/company/view"

const company = {
    namespaced: true,
    modules: {
        view,
    },
}

export default company
