export const ENTITY_DEAL =    "deal"
export const ENTITY_COMPANY = "company"
export const ENTITY_PERSON =  "person"

export const entityLabels = {
    [ENTITY_DEAL]: {
        singular: "oportunidade",
        plural: "oportunidades",
        endpointListing: "deals",
        endpointEntity: "deal",
    },
    [ENTITY_COMPANY]: {
        singular: "empresa",
        plural: "empresas",
        endpointListing: "companies",
        endpointEntity: "company",
    },
    [ENTITY_PERSON]: {
        singular: "pessoa",
        plural: "pessoas",
        endpointListing: "persons",
        endpointEntity: "person",
    },
}

export const capitalize = (text) => {
    return text[0].toUpperCase() + text.substring(1)
}
