
const meta = {
    pageTitle: "Relatórios",
    title: "",
    description: "",
    accessPermission: "C1",
    breadcrumb: [],
}

export default [
    {
        name: "reports.index",
        path: "reports",
        component: () => import("@/pages/reports/general/ReportsIndex"),
        meta,
        children: [
            {
                name: "reports.sold-items",
                path: "sold-items",
                component: () => import("@/pages/reports/general/soldItems/SoldItemsReport"),
                meta,
            },
            {
                name: "reports.deal-linked-items",
                path: "deal-linked-items",
                component: () => import("@/pages/reports/general/dealLinkedItems/DealLinkedItemsReport"),
                meta,
            },
            {
                name: "reports.processed-deals-days",
                path: "processed-deals-days",
                component: () => import("@/pages/reports/general/processedDeals/ByDayReport"),
                meta,
            },
            {
                name: "reports.accumulated-deals-days",
                path: "accumulated-deals-days",
                component: () => import("@/pages/reports/general/accumulatedDeals/ByDayAccumulatedReport"),
                meta,
            },
            {
                name: "reports.processed-deals-cities",
                path: "processed-deals-cities",
                component: () => import("@/pages/reports/general/processedDeals/ByCityReport"),
                meta,
            },
            {
                name: "reports.processed-deals-states",
                path: "processed-deals-states",
                component: () => import("@/pages/reports/general/processedDeals/ByUfReport"),
                meta,
            },
            {
                name: "reports.processed-deals-cnaes",
                path: "processed-deals-cnaes",
                component: () => import("@/pages/reports/general/processedDeals/ByCnaeReport"),
                meta,
            },
            {
                name: "reports.processed-deals-segments",
                path: "processed-deals-segments",
                component: () => import("@/pages/reports/general/processedDeals/BySegmentReport"),
                meta,
            },
            {
                name: "reports.processed-deals-microregions",
                path: "processed-deals-microregions",
                component: () => import("@/pages/reports/general/processedDeals/ByMicroregionReport"),
                meta,
            },
            {
                name: "reports.processed-deals-regions",
                path: "processed-deals-regions",
                component: () => import("@/pages/reports/general/processedDeals/ByRegionReport"),
                meta,
            },
            {
                name: "reports.processed-deals-weeks",
                path: "processed-deals-weeks",
                component: () => import("@/pages/reports/general/processedDeals/ByWeekReport"),
                meta,
            },
            {
                name: "reports.processed-deals-months",
                path: "processed-deals-months",
                component: () => import("@/pages/reports/general/processedDeals/ByMonthReport"),
                meta,
            },
            {
                name: "reports.processed-deals-origins",
                path: "processed-deals-origins",
                component: () => import("@/pages/reports/general/processedDeals/ByOriginReport"),
                meta,
            },
            {
                name: "reports.processed-deals-origin-groups",
                path: "processed-deals-origin-groups",
                component: () => import("@/pages/reports/general/processedDeals/ByOriginGroupReport"),
                meta,
            },
            {
                name: "reports.processed-deals-users",
                path: "processed-deals-users",
                component: () => import("@/pages/reports/general/processedDeals/ByUserReport"),
                meta,
            },
            {
                name: "reports.lost-reason-deals",
                path: "lost-reason-deals",
                component: () => import("@/pages/reports/general/lostReasonDeals/LostReasonDealReport"),
                meta,
            },
            {
                name: "reports.processed-deals-districts",
                path: "processed-deals-districts",
                component: () => import("@/pages/reports/general/processedDeals/ByDistrictReport"),
                meta,
            },
        ],
    },
]
