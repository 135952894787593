import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "tags.index",
        path: "settings/tags",
        component: () => import("@/pages/tagsSettings/TagsIndex"),
        meta: {
            title: "Tags",
            description: "Gerencie as tags de suas oportunidades, empresas e pessoas.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Tags",
                    show: ".*",
                },
            ],
        },
    },
]
