import environment from "@/shared/environment"
import { getStore } from "@/store"

export default [
    {
        path: "activities",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "activities.index",
                path: "",
                component: () => import("@/pages/activities/ActivitiesIndex"),
                beforeEnter: onlyAllowedV2Activities,
                meta: {
                    title: "Listagem de atividades",
                    accessPermission: "C1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Atividades",
                            show: ".*",
                        },
                    ],
                },
                children: [
                    {
                        name: "activities.list",
                        path: "",
                        props: true,
                        component: () => import("@/pages/activities/ActivitiesList"),
                    },
                    {
                        name: "activities.calendar",
                        path: "calendar",
                        props: true,
                        component: () => import("@/pages/activities/ActivitiesCalendar"),
                    },
                ],
            },
            {
                name: "activities.map",
                path: "map",
                component: () => import("@/pages/activities/ActivitiesMapIndex"),
                meta: {
                    customConfig: "ATIVIDADE_COORDENADAS_CONCLUIR",
                },
            },
        ],
    },
]

function onlyAllowedV2Activities(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.allows_v2_activities) {
        window.location = `${environment.APP_YII}/atividade`
    }

    next()
}
