export default [
    {
        name: "email.publicUnsubscribe",
        path: "email/unsubscribe/:hash",
        meta: {
            isPublic: true,
        },
        props: true,
        component: () => import("@/pages/emails/public/EmailUnsubscribe"),
    },
]
