import axios from "axios"
import { createResponseInterceptors } from "@/plugins/http/interceptors"
import environment from "@/shared/environment"

// allow use http client without Vue instance
export let http = null

export function createInstance() {
    if (!http) {
        http = axios.create({
            baseURL: environment.APP_API,
            headers: {"X-Application-Piperun": "CRM_Web_VueJs"},
        })
    }

    return http
}

/**
 * Helper method to set the header with the token
 */
export function setToken(token) {
    http.defaults.headers.common.Authorization = token
}

export default function install(app, { store, router }) {
    createInstance()
    createResponseInterceptors(http, store, router)
    app.config.globalProperties.$http = http
}
