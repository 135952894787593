export default {
    methods: {
        /* Redireciona com o router ou então com window.location */
        redirectHandler(nextLocation) {
            const resolvedLocation = this.$router.resolve(nextLocation)
            const locationHasV2Prefix = nextLocation.startsWith("/v2/")
            const pageExistsInRouter = !["redirect-notfound", "notfound"].includes(resolvedLocation.name)
            if (locationHasV2Prefix || pageExistsInRouter) {
                return this.$router.push({ path: resolvedLocation.path })
            }

            window.location = resolvedLocation.href
        },
    },
}
