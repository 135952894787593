<template>
    <div :class="alertClasses">
        <strong
            v-if="title"
            :class="['pipe-alert__title', { 'text-center': centerTitle }]"
        >
            <pipe-icon
                v-if="icon"
                :i="icon"
            /> {{ title }}
        </strong>
        <p><slot /></p>
    </div>
</template>

<script>
import PipeIcon from "@/components/formatters/PipeIcon"

export default {
    components: { PipeIcon },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        centerTitle: {
            type: Boolean,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        block: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: null,
        },
        leftBordered: {
            type: Boolean,
            default: null,
        },
        type: {
            type: String,
            required: false,
            default: "error",
            validator: value => {
                return ["error", "alert", "success"].includes(value)
            },
        },
    },
    computed: {
        alertClasses() {
            return [
                "pipe-alert",
                "pipe-alert--" + this.type,
                {
                    "pipe-alert--block": this.block,
                    "pipe-alert--outlined": this.outlined,
                    "pipe-alert--left-bordered": this.leftBordered,
                },
            ]
        },
    },
}
</script>

<style lang="less">
@import "src/assets/styles/color-variables.less";

.pipe-alert {
    display: inline-block;
    padding: 12px 16px;
    border: 1px dashed #333;
    background: #eee;
    border-radius: 4px;

    &__title {
        display: block;
    }

    &--block {
        display: block;
    }

    &--outlined {
        background-color: unset !important;
        border-style: solid;
    }

    &--left-bordered {
        background-color: #F0F2F6 !important;
        border: none;
        border-left-width: 5px;
        border-left-style: solid;
        border-left-color: #333;
    }

    &--error {
        border-color: @primary;
        background-color: lighten(@primary, 35%);

        strong {
            color: @primary;
        }

        p {
            color: darken(@primary, 20%);
            margin: 0;
        }
    }

    &--alert {
        border-color: @yellow-one;
        background-color: lighten(@yellow-one, 35%);

        strong {
            color: darken(@yellow-one, 5%);
        }

        p {
            color: darken(@yellow-one, 20%);
            margin: 0;
        }
    }

    &--success {
        border-color: @primary-green;
        background-color: lighten(@primary-green, 35%);

        strong {
            color: @primary-green;
        }

        p {
            color: darken(@primary-green, 25%);
            margin: 0;
        }
    }
}
</style>
