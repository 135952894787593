import { FILTER_ENTITY_PERSON } from "@/shared/variables/FilterEntities"

export default [
    {
        name: "persons.index",
        path: "persons",
        component: () => import("@/pages/persons/PersonsIndex"),
        meta: {
            pageTitle: "Pessoas",
            title: "Pessoas",
            mixpanelTrackOrigin: "Listagem de pessoas",
            filtersSelect: {
                entityId: FILTER_ENTITY_PERSON,
            },
        },
    },
]
