import { USER_PERMISSION_G1 } from "@/shared/variables/UserPermissionVariables"
import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "MeasurementUnits.index",
        path: "settings/measurement-units",
        component: () => import("@/pages/measurementUnitsSettings/MeasurementUnitsIndex"),
        meta: {
            title: "Unidades de medida",
            description: "Gerencie as unidades de medida que serão atribuídas aos produtos.",
            accessPermission: USER_PERMISSION_G1,
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Unidades de medida",
                    show: ".*",
                },
            ],
        },
    },
]
