/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-computed-key */
export default {
    ["SET_DATA"](state, obj) {
        state.list = obj.list
        state.pagination = obj.pagination
    },
    ["SET_STATS"](state, obj) {
        state.stats = obj.stats
    },
}
