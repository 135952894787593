import { http } from "@/plugins/http"

export const listCurrencies = ({ commit }) => {
    return new Promise((resolve, reject) => {
        http.get("currencies").then(({ data }) => {
            const obj = {
                list: data.data,
            }
            commit("SET_DATA", obj)
            resolve(obj)
        }, error => {
            reject(error)
        })
    })
}
