import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"
import { USER_PERMISSION_ADMIN } from "@/shared/variables/UserPermissionVariables"

export default [
    {
        name: "administration.account.created",
        path: "administration/account/created",
        component: () => import("@/pages/administration/AccountActionIndex"),
        meta: {
            pageTitle: "Accounts | Administração",
            title: "Oportunidades após gestão de contas",
            description: "Gerencie funil e etapa onde serão adicionadas as oportunidades geradas após a gestão de contas.",
            accessPermission: USER_PERMISSION_ADMIN,
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Accounts",
                    show: ".*",
                },
            ],
        },
    },
]
