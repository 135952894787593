import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "integrations.exactSpotter",
        path: "integrations/exact-spotter",
        component: () => import("@/pages/integrations/exactSpotter/ExactSpotterIndex"),
        meta: {
            pageTitle: "Integrações | Exact Spotter V2",
            title: "Integração Exact Spotter V2",
            description: "Configure a integração do PipeRun com a sua conta na Exact Spotter.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Integração Exact Spotter V2",
                    show: ".*",
                },
            ],
        },
    },
]

