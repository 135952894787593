import Account from "@/router/routes/settings/Account"
import ActivityTypes from "@/router/routes/settings/ActivityTypes"
import EconomicGroups from "@/router/routes/settings/EconomicGroups"
import AutomaticMovements from "@/router/routes/settings/AutomaticMovements"
import Automations from "@/router/routes/settings/Automations"
import ItemCategories from "@/router/routes/settings/ItemCategories"
import CustomFields from "@/router/routes/settings/CustomFields"
import DealSettings from "@/router/routes/settings/DealSettings"
import Exports from "@/router/routes/settings/Exports"
import Holding from "@/router/routes/settings/Holding"
import Imports from "@/router/routes/settings/Imports"
import Items from "@/router/routes/settings/Items"
import LossReasons from "@/router/routes/settings/LossReasons"
import Origins from "@/router/routes/settings/Origins"
import Segments from "@/router/routes/settings/Segments"
import PaymentMethods from "@/router/routes/settings/PaymentMethods"
import ProposalTemplate from "@/router/routes/settings/ProposalTemplate"
import Regions from "@/router/routes/settings/Regions"
import OpenIdConnect from "@/router/routes/settings/OpenIdConnectSettings"
import Tags from "@/router/routes/settings/Tags"
import CommissionRules from "@/router/routes/settings/CommissionRules"
import UserImporter from "@/router/routes/settings/UserImporter"
import UserSettings from "@/router/routes/settings/UserSettings"
import WebhookSettings from "@/router/routes/settings/Webhook"
import DocumentModels from "@/router/routes/settings/DocumentModels"
import DealRequiredFieldRules from "@/router/routes/settings/DealRequiredFieldRules"
import MeasurementUnits from "@/router/routes/settings/MeasurementUnits"
import Versions from "@/router/routes/settings/Versions"
import BulkActionsIndex from "@/router/routes/settings/BulkActions"
import CustomForms from "@/router/routes/settings/CustomForms"
import ImportsV3 from "@/router/routes/settings/ImportsV3"
import environment from "@/shared/environment"
import TemplatesEmail from "@/router/routes/settings/TemplatesEmail"
import ItemCharacteristicsGrid from "@/router/routes/settings/ItemCharacteristicsGrid"
import ItemCharacteristics from "@/router/routes/settings/ItemCharacteristics"
import ItemCharacteristicManagement from "@/router/routes/settings/ItemCharacteristicManagement"
import Mapper from "@/router/routes/settings/Mapper"
import DistributionSettings from "@/router/routes/settings/DistributionSettings"
import DataListSettings from "@/router/routes/settings/DataListSettings"
import ProposalModelSettings from "@/router/routes/settings/ProposalModelSettings"
import ProposalRole from "@/router/routes/settings/ProposalInvolvedRole"

export default [
    {
        name: "settings.index",
        path: "settings",
        component: () => import("@/pages/settings/SettingsIndex"),
        meta: {
            pageTitle: "Ajustes e configurações",
            title: "Ajustes e configurações de conta",
            description: "Faça as configurações necessárias para extrair o máximo de sua conta.",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Ajustes e configurações",
                    show: ".*",
                },
            ],
        },
    },

    ...Account,
    ...ActivityTypes,
    ...EconomicGroups,
    ...AutomaticMovements,
    ...DealSettings,
    ...Exports,
    ...Imports,
    ...ImportsV3,
    ...Holding,
    ...Items,
    ...ItemCategories,
    ...ItemCharacteristicsGrid,
    ...ItemCharacteristics,
    ...ItemCharacteristicManagement,
    ...LossReasons,
    ...CustomForms,
    ...Origins,
    ...Segments,
    ...PaymentMethods,
    ...ProposalTemplate,
    ...ProposalRole,
    ...Regions,
    ...Tags,
    ...CommissionRules,
    ...OpenIdConnect,
    ...UserSettings,
    ...WebhookSettings,
    ...DocumentModels,
    ...DealRequiredFieldRules,
    ...MeasurementUnits,
    ...Versions,
    ...BulkActionsIndex,
    ...TemplatesEmail,
    ...Mapper,
    ...DistributionSettings,
    ...DataListSettings,
    ...ProposalModelSettings,

    /* Rotas fora do "/settings" */
    ...Automations,
    ...CustomFields,
    ...UserImporter, // Importer antigo, remover quando todas as importações estiverem refatoradas no v2
]
