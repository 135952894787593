import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

const meta = {
    title: "Administração de holding",
    description: "Gerencie multiplas contas usando seu login.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Administração de holding",
            show: ".*",
        },
    ],
}
export default [
    {
        name: "holding",
        path: "settings/holding",
        component: () => import("@/pages/holdingSettings/HoldingIndex"),
        meta: meta,
        children: [
            {
                name: "holding.replicate",
                path: "account/:toAccountId/replicate",
                props: true,
                component: () => import("@/pages/holdingSettings/replicate/ReplicateIndex"),
                meta: meta,
                children: [
                    {
                        name: "holding.replicate.customForms",
                        path: "custom-forms",
                        props: true,
                        component: () => import("@/pages/holdingSettings/replicate/ReplicateCustomForms"),
                        meta: meta,
                    },
                    {
                        name: "holding.replicate.emailTemplates",
                        path: "email-templates",
                        component: () => import("@/pages/itemsSettings/ItemsIndex"),
                        meta: meta,
                    },
                ],
            },
        ],
    },
]
