import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "pipelines",
        path: "settings/pipelines",
        component: () => import("@/pages/pipelinesSettings/PipelineSettingsIndex"),
        meta: {
            title: "Funis e etapas",
            accessPermission: "G1",
            description: "Gerencie os funis e suas etapas de maneira simples para adaptar ao seu processo de vendas.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Funis e etapas",
                    show: ".*",
                },
            ],
            searchFilter: true,
        },
    },
]
