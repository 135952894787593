import { USER_PERMISSION_C1 } from "@/shared/variables/UserPermissionVariables"

export default [
    {
        name: "dealRanking.index",
        path: "deal-ranking",
        components: {
            default: () => import("@/pages/reports/rankings/DealRankingIndex"),
        },
        meta: {
            pageTitle: "Ranking dinâmico de oportunidade",
            title: "Ranking dinâmico de oportunidade",
            mixpanelTrackOrigin: "Ranking dinâmico de oportunidade",
            accessPermission: USER_PERMISSION_C1,
        },
    },
]
