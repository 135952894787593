import environment from "@/shared/environment"

export default [
    {
        label: "Home",
        href: environment.APP_YII,
        show: ".*",
        external: true,
    },
    {
        label: "Ajustes e configurações",
        show: ".*",
        href: "/v2/settings",
    },
]
