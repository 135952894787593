import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "integrations.openAI",
        path: "integrations/openai",
        component: () => import("@/pages/integrations/openAi/OpenAiIndex"),
        meta: {
            pageTitle: "Integrações | OpenAI",
            title: "Integração OpenAI",
            description: "Configure a integração do PipeRun com a sua conta OpenAI.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Integração OpenAI",
                    show: ".*",
                },
            ],
        },
    },
]

