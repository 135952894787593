
export default [
    {
        name: "signatures.publicSearch",
        path: "signatures/search/:documentHash?",
        component: () => import("@/pages/signatures/public/SignaturesPublicSearch"),
        meta: {
            isPublic: true,
        },
    },
]
