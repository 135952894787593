export const COMMISSION_TYPE_NO = 1
export const COMMISSION_TYPE_STANDARD = 2
export const COMMISSION_TYPE_VARIABLE_DISCOUNT = 3
export const COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT = 4

const COMMISSION_CALC_TYPE_PERCENT = 1
const COMMISSION_CALC_TYPE_ABSOLUTE = 2
const COMMISSION_CALC_TYPE_BOTH = 3

export const commissionTypeOptions = [
    {
        id: COMMISSION_TYPE_NO,
        name: "Sem comissão",
        description: "Não existirá comissão para usuários.",
    },
    {
        id: COMMISSION_TYPE_STANDARD,
        name: "Comissão padrão por item (P&S e MRR) na proposta",
        description: "Defina nos itens o valor de comissão que ele terá.",
    },
    {
        id: COMMISSION_TYPE_VARIABLE_DISCOUNT,
        name: "Comissão e desconto variável por item (P&S e MRR) na proposta",
        description: "Defina nos itens, as faixas de descontos e comissões que serão geradas nas propostas dos usuários.",
    },
    {
        id: COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT,
        name: "Comissão consolidada por valor recebido no forecast",
        description: "Faça lançamentos de comissões a partir da consolidação de valores recebidos no forecast, com regras de comissionamentos por papel do envolvido na proposta.",
    },
]

export const commissionType = [
    { id: COMMISSION_CALC_TYPE_PERCENT, name: "Percentual (%)" },
    { id: COMMISSION_CALC_TYPE_ABSOLUTE, name: "Valor fixo (R$)" },
    { id: COMMISSION_CALC_TYPE_BOTH, name: "Ambos" },
]
