import { http } from "@/plugins/http"
import { isEmpty } from "lodash-es"

export const setCompanyData = ({ commit }, companyData) => {
    commit("SET_COMPANY_DATA", companyData)
}

export const setCompanyDefaultParams = ({ commit }, params) => {
    commit("SET_COMPANY_DEFAULT_PARAMS", params)
}

export const updateCompanyData = ({ getters, commit }, payload) => {
    Object.keys(payload).forEach(key => {
        // Nulla campos vazios para não enviar strings e outros objetos vazios
        if (payload[key] !== null &&
            typeof (payload[key]) !== "number" &&
            typeof (payload[key]) !== "boolean" &&
            isEmpty(payload[key])) {
            payload[key] = null
        }
    })

    const config = {
        params: getters.companyDefaultParams,
    }
    return new Promise((resolve, reject) => {
        http.put(`companies/${getters.companyId}`, payload, config)
            .then(({ data }) => {
                const companyData = data.data
                commit("SET_COMPANY_DATA", companyData)
                resolve(companyData)
            }, error => reject(error))
    })
}
