import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.proposal.proposal-model",
        path: "settings/proposal/proposal-model",
        components: {
            default: () => import("@/pages/proposalModelSettings/ProposalModelSettingsIndex"),
        },
        meta: {
            title: "Modelos de proposta",
            accessPermission: "G1",
            description: "Crie modelos de proposta a partir de documentos PDF com uma ou mais páginas. Esses modelos poderão ser utilizados para gerar PDFs de proposta com uma apresentação mais elaborada. Em cada modelo, você indicará onde estará posicionada a página da proposta no documento.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Modelos de proposta",
                    show: ".*",
                },
            ],
        },
    },
]
