import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "settings.mapper",
        path: "settings/mapper/:integration?",
        components: {
            default: () => import("@/pages/mapperSettings/MapperSettingsIndex"),
        },
        meta: {
            title: "Mapeador de campos",
            accessPermission: "G1",
            description: "Configure os mapeamentos de campos necessários para o recebimento de dados.",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Mapeador de campos",
                    show: ".*",
                },
            ],
        },
    },
]
