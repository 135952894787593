import environment from "@/shared/environment"

export function loadElevio(callBack) {
    if (!environment.APP_ELEVIO_ID) {
        return
    }

    !function (e, l, v, i, o, n) {
        if (!e[i]) {
            e[i] = {}
        }
        e[i].account_id = n
        let g, h

        g = l.createElement(v)
        g.type = "text/javascript"
        g.async = true
        g.src = o + n

        h = l.getElementsByTagName(v)[0]
        h.parentNode.insertBefore(g, h)
        g.onload = function () {
            if (callBack && typeof callBack === "function") {
                callBack()
            }
        }
        e[i].q = []
        e[i].on = function (z, y) {
            e[i].q.push([z, y])
        }
    }(window, document, "script", "_elev", "https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=", environment.APP_ELEVIO_ID)
}

