import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"

export default [
    {
        name: "items.index",
        path: "settings/items",
        component: () => import("@/pages/itemsSettings/ItemsIndex"),
        meta: {
            title: "Produtos, serviços ou MRR",
            description: "Gerencie os produtos, serviços e MRRs que serão utilizados na elaboração de propostas de oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Produtos, serviços ou MRR",
                    show: ".*",
                },
            ],
        },
    },
]
