export default [
    {
        name: "customFormsNew.publicView",
        path: "/v2/custom-form-public/:formHash?",
        component: () => import("@/pages/customForms/PublicView/CustomFormIndex"),
        meta: {
            isPublic: true,
        },
    },
    {
        name: "customFormsNew.publicPreview",
        path: "/v2/custom-form-preview/:formId?",
        component: () => import("@/pages/customForms/PublicView/CustomFormPreview"),
        meta: {
            isPublic: false,
        },
    },
]
