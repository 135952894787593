export default {
    ["SET_CUSTOM_FORM_DATA"](state, value) {
        state.customFormData = value
    },
    ["SET_DRAGGING_FIELD"](state, value) {
        state.draggingField = value
    },
    ["SET_AVAILABLE_FIELDS"](state, value) {
        state.availableFields = value
    },
    ["SET_CUSTOM_FORM_FIELDS"](state, value) {
        state.customFormFields = value
    },
    ["SET_HAS_FIELDS"](state, value) {
        state.hasFields = value
    },
}
