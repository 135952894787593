export default {
    ["SET_IS_SHOWING"](state, value) {
        state.isShowing = value
    },
    ["SET_ENTITY"](state, value) {
        state.entity = value
    },
    ["SET_ENTITY_ID"](state, value) {
        state.entityId = value
    },
    ["SET_ENTITY_DATA"](state, value) {
        state.entityData = value
    },
}
